const
    testOptions  = {
         PAGE_ROWS:      100
        ,FILTERS_LIMIT:  10
    }
    ,prodOptions = {
         PAGE_ROWS:      100
        ,FILTERS_LIMIT:  100
    }
    ,devParams   = {      ...prodOptions, API_URL: 'https://backend.systrategy.it/roobeek-dev'            }

    ,environment = {

         local:         { ...testOptions, API_URL: 'http://localhost:65001'                               }
        ,stage:         { ...prodOptions, API_URL: 'https://backend.systrategy.it/roobeek-stage'          }
        ,dev:           devParams

        ,proddisco:     { ...prodOptions, API_URL: 'https://backend.roobeek.wbdiscovery.systrategy.com/roobeek'   }
        ,prodcairo:     { ...prodOptions, API_URL: 'https://backend.roobeek.cairorcsmedia.systrategy.com/roobeek' }
        ,prodsole:      { ...prodOptions, API_URL: 'https://backend.roobeek.ilsole24ore.systrategy.com/roobeek'   }
        ,prodigp:       { ...prodOptions, API_URL: 'https://backend.roobeek.igp.systrategy.com/roobeek'           }
        ,prodconde:     { ...prodOptions, API_URL: 'https://backend.condenast.systrategy.com/b8edd68654c007ec41eaae0faa3509a0e160ef83e83bef16903a79e6b811f299' }

    }
    ,config      = () => ({
        ...( environment[ process.env.REACT_APP_ENV ] || environment[ localStorage.getItem('appid') ] || {} )
        ,ENVIRONMENTS: environment
    })
;

export default config();
